import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          <path d="M2295 3854 c-327 -48 -586 -176 -810 -399 -89 -89 -193 -226 -225
-297 -12 -24 -16 -26 -129 -32 -214 -13 -405 -56 -484 -107 -90 -59 -87 -129
7 -220 73 -70 306 -190 334 -172 32 20 21 33 -71 77 -184 90 -257 151 -257
214 0 55 85 99 247 126 89 16 303 33 303 25 0 -3 -16 -43 -35 -89 -19 -47 -43
-120 -52 -163 -15 -65 -22 -79 -41 -84 -12 -3 -26 -13 -31 -22 -9 -17 -1 -97
17 -166 6 -22 13 -88 17 -147 21 -341 158 -642 405 -888 205 -205 418 -321
703 -382 130 -27 364 -29 507 -4 428 76 808 361 1000 751 59 121 110 280 130
405 5 36 10 124 10 196 0 72 3 145 6 162 l6 31 81 -30 c106 -39 259 -116 325
-164 81 -58 104 -121 59 -163 -31 -30 -157 -69 -263 -82 -156 -20 -154 -19
-154 -40 0 -31 41 -34 174 -16 242 35 360 104 342 201 -8 39 -63 105 -122 145
-63 44 -245 133 -350 173 -50 19 -100 42 -111 53 -10 10 -25 44 -32 74 -23 95
-52 172 -103 275 -44 88 -112 198 -142 232 -6 6 -36 40 -66 75 -168 195 -420
349 -680 415 -125 32 -148 35 -310 38 -96 2 -188 1 -205 -1z m165 -24 c0 -16
-15 -18 -123 -22 -65 -3 -96 0 -94 7 7 20 217 35 217 15z m222 -6 c22 -14 -13
-19 -109 -16 -86 3 -103 6 -103 19 0 14 13 15 101 9 56 -3 106 -9 111 -12z
m-462 -12 c0 -9 -169 -46 -176 -39 -5 5 134 45 159 46 9 1 17 -3 17 -7z m609
-18 c29 -8 51 -17 49 -20 -8 -7 -162 27 -173 38 -12 13 45 4 124 -18z m-369
-53 l0 -59 -87 -6 c-49 -4 -114 -8 -145 -11 -68 -6 -71 0 -33 75 28 55 24 53
198 58 l67 2 0 -59z m243 43 c12 -4 57 -101 57 -120 0 -3 -21 -2 -47 0 -27 3
-92 8 -145 12 l-98 6 0 60 0 61 110 -7 c60 -3 116 -9 123 -12z m-516 -28 c-9
-13 -20 -38 -24 -56 -5 -19 -15 -37 -23 -40 -22 -11 -242 -52 -247 -46 -3 3
16 32 41 65 45 58 48 60 119 75 39 8 81 17 92 19 11 3 28 5 39 6 17 1 17 -1 3
-23z m694 -2 c62 -14 68 -18 112 -76 26 -34 45 -63 43 -65 -3 -3 -128 19 -243
43 -13 2 -23 11 -23 20 0 8 -9 32 -20 54 -11 22 -20 42 -20 46 0 8 57 -1 151
-22z m-977 -90 c-45 -66 -46 -67 -132 -96 -47 -16 -92 -34 -99 -40 -31 -25
-23 5 12 47 48 60 160 128 240 148 11 2 21 5 22 6 2 0 -18 -29 -43 -65z m1163
31 c36 -14 85 -40 107 -57 46 -36 111 -106 105 -113 -3 -2 -44 11 -92 30 -48
19 -93 35 -102 35 -8 0 -27 19 -41 43 -15 23 -36 52 -47 65 -26 28 -13 28 70
-3z m-607 -69 l0 -33 -117 -7 c-65 -4 -136 -10 -159 -13 l-41 -5 5 31 c2 17 9
34 16 38 18 12 95 20 199 22 l97 1 0 -34z m247 25 c62 -9 61 -8 69 -51 l7 -33
-47 6 c-25 4 -96 10 -156 13 l-110 7 0 34 0 33 93 -1 c50 0 116 -4 144 -8z
m-567 -25 c0 -8 -4 -25 -9 -38 -8 -21 -20 -26 -90 -37 -44 -6 -106 -19 -137
-27 -31 -8 -59 -13 -61 -10 -2 2 4 19 13 38 15 33 21 36 123 60 125 31 161 34
161 14z m818 -17 c88 -20 94 -23 109 -55 9 -19 15 -36 12 -38 -2 -2 -38 4 -80
14 -41 10 -103 21 -137 25 -57 6 -63 9 -68 33 -3 15 -8 33 -10 41 -5 17 37 12
174 -20z m-1124 -74 c-17 -33 -27 -40 -107 -66 -49 -16 -98 -34 -109 -40 -28
-15 -32 -6 -13 30 11 22 33 39 73 58 60 27 164 62 171 57 2 -2 -5 -19 -15 -39z
m624 -21 l-3 -49 -115 -6 c-63 -4 -137 -8 -165 -10 l-50 -4 3 35 c5 43 10 72
15 76 7 7 302 20 310 13 5 -3 7 -28 5 -55z m328 43 c1 -1 5 -29 9 -61 l8 -59
-29 6 c-16 4 -91 9 -166 13 l-138 7 0 56 0 56 157 -8 c86 -4 157 -8 159 -10z
m463 -42 c44 -20 62 -35 74 -61 9 -19 15 -34 14 -34 -2 0 -51 18 -110 40 -58
22 -110 40 -114 40 -8 0 -33 56 -33 73 0 8 93 -24 169 -58z m-1611 22 c-18
-29 -115 -93 -125 -83 -4 4 129 116 139 116 5 0 -2 -15 -14 -33z m1707 -18
c33 -28 62 -56 64 -62 9 -23 -101 50 -120 81 -29 45 -13 40 56 -19z m-1225 7
c0 -13 -3 -37 -6 -55 -5 -26 -12 -32 -43 -37 -20 -3 -87 -17 -149 -31 -63 -13
-115 -23 -117 -21 -1 2 4 27 13 56 14 51 16 53 68 68 52 15 182 41 217 43 12
1 17 -6 17 -23z m805 5 c174 -36 170 -34 183 -92 7 -29 11 -54 8 -56 -2 -2
-52 7 -112 21 -60 13 -128 27 -151 31 -40 6 -43 9 -43 38 0 18 -3 42 -6 55 -8
28 1 28 121 3z m-1115 -56 c0 -2 -5 -26 -11 -53 l-10 -50 -92 -32 c-51 -18
-105 -39 -120 -47 -16 -8 -31 -12 -33 -10 -3 2 4 29 14 58 l18 54 105 42 c98
40 129 49 129 38z m1382 -17 c35 -12 85 -32 113 -44 44 -21 51 -28 66 -74 9
-28 14 -52 10 -54 -3 -2 -49 14 -101 35 -52 21 -102 38 -111 39 -22 0 -35 18
-42 55 -3 17 -9 38 -12 48 -9 22 1 22 77 -5z m-1602 7 c0 -24 -38 -69 -78 -91
-26 -15 -58 -36 -71 -46 -32 -24 -36 -17 -12 22 24 41 161 138 161 115z m1824
-42 c37 -27 78 -64 89 -83 l22 -35 -35 23 c-19 13 -56 35 -82 50 -28 16 -53
40 -62 60 -9 17 -12 32 -8 32 5 0 39 -21 76 -47z m-957 -109 l2 -131 -117 -7
c-64 -3 -144 -9 -176 -13 l-59 -6 7 134 c4 73 8 135 10 136 5 5 254 21 296 19
l35 -1 2 -131z m265 120 c91 -7 88 -1 88 -163 l0 -114 -47 7 c-27 4 -103 9
-170 13 l-123 6 0 135 0 134 88 -6 c48 -3 121 -9 164 -12z m-616 -75 c-3 -34
-6 -94 -6 -133 l0 -71 -128 -23 c-77 -14 -140 -20 -161 -16 l-34 7 6 86 c11
149 10 147 81 166 75 20 192 43 225 44 23 1 24 0 17 -60z m835 45 c46 -8 107
-22 136 -30 l51 -15 6 -72 c4 -40 9 -101 13 -136 6 -64 6 -64 -18 -57 -54 15
-197 45 -245 51 l-51 6 -7 67 c-3 37 -6 97 -6 135 0 78 -7 75 121 51z m-1408
-88 c-9 -31 -20 -56 -24 -56 -3 0 -41 -23 -84 -51 -42 -27 -79 -48 -82 -46 -7
8 37 104 56 121 22 20 145 95 149 91 1 -1 -5 -28 -15 -59z m1913 23 c27 -17
60 -41 72 -53 26 -23 77 -115 68 -123 -2 -3 -20 6 -38 20 -18 14 -58 39 -89
56 -49 27 -58 37 -64 69 -4 20 -11 43 -17 50 -19 23 16 13 68 -19z m-1670 -41
c-3 -29 -6 -82 -6 -117 l0 -63 -127 7 c-71 4 -129 8 -130 9 -2 1 2 30 8 63 10
58 12 61 52 78 64 27 191 74 201 74 5 1 6 -23 2 -51z m1481 17 c125 -46 139
-55 148 -102 11 -59 21 -223 14 -223 -4 0 -31 11 -60 24 -30 13 -86 34 -125
46 l-71 23 -6 111 c-4 61 -10 121 -13 134 -8 29 -3 28 113 -13z m-1896 -48
c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m143 -86 l-7 -51 -80
0 c-69 0 -79 2 -67 14 21 19 153 97 157 92 2 -2 1 -27 -3 -55z m2009 7 c89
-59 99 -74 115 -183 18 -120 21 -143 15 -148 -3 -3 -26 11 -51 30 -26 20 -71
47 -99 61 -51 25 -53 28 -54 67 0 22 -2 47 -3 55 -2 8 -6 43 -10 78 -4 35 -10
70 -13 78 -10 26 20 15 100 -38z m-2182 -5 c-13 -34 -25 -49 -41 -51 -20 -3
-20 -2 -10 20 11 21 60 78 67 78 1 0 -6 -21 -16 -47z m2328 -53 c17 -32 31
-61 31 -66 0 -18 -81 74 -89 101 -5 17 -12 39 -17 50 -16 42 43 -25 75 -85z
m-1201 -27 c4 -63 12 -61 -153 -38 -60 9 -129 18 -152 21 -34 4 -43 9 -43 25
0 11 6 22 14 25 20 8 212 22 276 20 l55 -1 3 -52z m387 26 c77 -11 177 -29
221 -41 l82 -20 -5 -71 c-3 -40 -6 -73 -7 -74 0 -1 -71 13 -156 30 l-155 32
-1 63 c-2 85 -14 93 -14 9 0 -38 -4 -67 -10 -67 -5 0 -49 6 -97 14 -49 8 -121
20 -160 26 l-73 12 0 60 0 59 118 -6 c64 -3 180 -15 257 -26z m-745 -20 c0
-19 -4 -21 -37 -15 -21 3 -53 6 -72 6 -45 0 -32 12 22 21 77 12 87 11 87 -12z
m1611 -108 c34 -52 69 -157 69 -207 0 -46 -27 -45 -80 2 -35 32 -40 42 -40 79
0 24 -7 79 -15 122 -8 43 -15 82 -15 87 0 15 49 -35 81 -83z m-2430 3 c-6 -32
-11 -83 -11 -115 0 -54 -2 -58 -45 -98 -24 -22 -53 -41 -63 -41 -40 0 5 190
62 265 63 82 74 80 57 -11z m209 27 l0 -50 -62 -37 c-35 -21 -80 -51 -101 -67
-21 -16 -41 -27 -44 -23 -9 8 15 184 28 207 8 16 22 19 94 19 l85 0 0 -49z
m194 30 c-7 -6 -69 -31 -166 -67 -15 -5 -18 0 -18 33 0 22 2 42 4 44 2 2 46 3
96 1 51 -2 89 -7 84 -11z m88 -81 c1 -44 2 -94 2 -112 l1 -32 -130 -16 c-71
-9 -131 -16 -132 -16 -1 1 -5 36 -9 78 l-7 76 34 18 c40 20 208 83 227 84 8 0
13 -23 14 -80z m189 69 l137 -12 6 -30 c3 -16 6 -55 6 -87 l0 -57 -102 -7
c-78 -5 -109 -11 -130 -26 -24 -18 -29 -18 -56 -5 -28 14 -29 19 -35 97 -11
132 -10 138 15 138 12 0 84 -5 159 -11z m1291 -15 c83 -30 161 -65 168 -75 3
-4 3 -29 2 -56 l-4 -49 -131 31 c-79 19 -132 36 -131 43 1 7 2 39 3 72 0 33 5
60 10 60 6 0 43 -12 83 -26z m-956 -24 c82 -11 152 -20 157 -20 10 0 9 -128
-2 -135 -5 -3 -13 -1 -16 5 -4 7 -61 10 -157 9 -82 0 -151 1 -152 3 -2 1 -6
38 -8 81 -6 91 -26 84 178 57z m289 -40 c39 -7 102 -18 142 -26 40 -8 75 -14
78 -14 4 0 5 -12 3 -27 l-3 -28 -141 -3 c-110 -2 -145 -6 -154 -17 -7 -8 -19
-15 -26 -15 -11 0 -14 18 -14 74 0 83 -14 76 115 56z m-1093 -91 c2 -39 -1
-73 -5 -76 -7 -4 -178 -32 -199 -33 -5 0 -8 12 -8 28 0 23 13 35 97 90 54 34
101 62 105 62 3 0 8 -32 10 -71z m2010 39 c92 -56 149 -100 145 -112 -3 -6 -8
-10 -13 -10 -35 6 -181 48 -189 55 -9 7 -8 99 1 99 2 0 27 -14 56 -32z m-547
-14 c177 -37 176 -36 173 -53 -4 -17 -5 -17 -117 0 -62 9 -80 9 -114 -5 -37
-15 -42 -15 -59 1 -10 9 -18 30 -18 49 0 38 -10 37 135 8z m255 -54 c21 -7 19
-8 -17 -9 -23 -1 -45 4 -48 9 -7 12 29 12 65 0z m-790 -65 c0 -52 -1 -55 -25
-55 l-25 0 0 -100 0 -100 -60 0 -59 0 -3 98 -3 97 -27 3 c-26 3 -28 6 -28 52
l0 49 48 4 c26 2 77 4 115 5 l67 2 0 -55z m328 46 c84 -8 92 -15 92 -84 0 -43
-3 -49 -27 -58 l-27 -10 24 -12 c31 -16 39 -76 15 -113 -15 -23 -19 -24 -150
-24 -81 0 -136 4 -140 10 -4 6 -3 76 1 155 l7 145 66 -1 c36 0 99 -4 139 -8z
m-578 -46 l0 -45 -37 0 c-55 0 -143 -12 -143 -19 0 -3 33 -5 73 -3 66 3 74 1
95 -21 20 -21 23 -33 20 -82 -4 -83 -20 -92 -175 -101 l-123 -7 0 47 0 46 53
1 c28 0 70 4 92 8 35 8 31 9 -37 10 -70 1 -78 3 -97 27 -30 38 -29 123 0 150
22 19 86 29 217 32 l62 2 0 -45z m330 -40 c0 -69 -3 -85 -15 -85 -12 0 -15 16
-15 85 0 69 3 85 15 85 12 0 15 -16 15 -85z m592 45 c19 -23 20 -35 15 -130
-5 -99 -7 -106 -33 -128 -26 -23 -34 -24 -97 -18 -118 11 -128 25 -118 162 4
62 12 99 24 118 18 28 20 28 104 24 76 -3 87 -6 105 -28z m198 5 c98 -11 117
-17 133 -36 14 -18 17 -32 11 -63 -8 -52 -11 -56 -35 -56 -10 0 -19 -4 -19
-10 0 -5 7 -10 15 -10 20 0 29 -41 21 -95 l-7 -45 -44 0 c-70 0 -74 4 -68 66
l6 54 -37 0 c-34 0 -36 -2 -36 -34 0 -58 -7 -66 -61 -66 -36 0 -51 4 -56 16
-5 14 8 171 23 262 6 37 -3 36 154 17z m-1460 -15 c30 -30 26 -120 -6 -120
-23 0 -16 -18 8 -22 25 -3 35 -30 37 -94 l1 -41 -52 -6 c-29 -2 -54 -3 -56 -2
-2 2 -6 25 -10 52 -4 29 -12 48 -20 47 -6 -1 -21 -2 -32 -2 -17 -2 -20 -9 -20
-56 l0 -54 -51 -7 c-27 -4 -52 -5 -54 -3 -7 7 -37 256 -33 280 3 21 10 24 98
34 147 17 167 17 190 -6z m-562 -2 c-3 -7 -12 -14 -21 -16 -12 -2 -15 1 -11
12 7 19 38 22 32 4z m268 -159 c12 -79 19 -147 15 -150 -13 -14 -111 -21 -111
-9 0 6 -9 72 -21 147 -11 74 -18 138 -14 141 6 7 85 20 100 17 6 -1 20 -66 31
-146z m2054 136 c52 -9 96 -17 98 -19 2 -2 -2 -40 -8 -84 -12 -82 -8 -140 4
-67 4 22 12 64 18 94 l10 54 50 -6 c28 -4 53 -9 56 -12 5 -5 -2 -59 -30 -227
-10 -59 -14 -68 -32 -68 -28 0 -198 28 -203 34 -2 2 3 41 11 87 8 46 12 86 10
88 -9 10 -16 -11 -27 -85 l-12 -79 -52 0 c-29 1 -55 4 -58 7 -3 3 4 72 17 152
17 115 25 146 38 146 8 0 58 -7 110 -15z m-2206 -27 c3 -13 6 -37 6 -54 l0
-31 -85 -13 c-47 -7 -88 -17 -91 -22 -7 -11 8 -10 91 6 39 8 73 11 77 8 3 -4
9 -28 13 -53 l7 -46 -81 -13 -81 -12 0 -43 c0 -40 -2 -43 -31 -49 -58 -11 -78
-7 -84 17 -6 26 -34 186 -42 238 l-5 36 123 22 c68 12 129 24 134 26 26 9 43
3 49 -17z m1076 -43 c0 -21 -5 -25 -30 -25 -34 0 -41 23 -12 39 28 17 42 12
42 -14z m0 -119 l0 -73 -127 -7 c-71 -4 -138 -10 -150 -13 -33 -8 -29 19 7 55
26 26 30 28 30 11 0 -34 40 -50 109 -43 72 8 82 20 76 98 -4 45 -3 46 26 46
l29 0 0 -74z m40 16 c0 -32 5 -63 12 -70 7 -7 33 -12 58 -12 80 0 201 -13 193
-20 -4 -4 -39 -4 -78 1 -38 4 -103 8 -142 8 l-73 1 0 75 c0 60 3 75 15 75 12
0 15 -13 15 -58z m487 -102 c66 0 73 -2 73 -20 0 -17 -5 -19 -42 -15 -24 2
-80 7 -125 10 -120 8 -144 22 -81 48 27 11 36 11 67 -5 24 -12 58 -18 108 -18z
m-1579 -18 c2 -12 12 -28 23 -36 19 -13 18 -15 -20 -45 -22 -17 -42 -28 -44
-24 -4 7 -37 113 -37 119 0 4 44 12 60 10 8 -1 16 -11 18 -24z m742 19 c0 -12
-34 -19 -142 -30 -59 -6 -92 -14 -104 -25 -14 -14 -19 -15 -25 -4 -5 7 -9 20
-9 30 0 13 15 17 88 23 48 3 98 7 112 9 55 7 80 6 80 -3z m1165 -31 c23 0 25
-3 19 -22 -8 -26 -53 -88 -64 -88 -4 0 -19 23 -34 50 -27 50 -28 50 -77 50
-42 0 -49 -3 -49 -20 0 -24 -13 -26 -30 -5 -18 21 -4 68 18 61 9 -3 42 -8 73
-11 45 -6 58 -4 69 10 13 16 15 16 31 -4 10 -12 29 -21 44 -21z m-1578 19 c6
-6 33 -10 62 -8 45 4 51 2 56 -19 7 -30 -1 -42 -31 -42 -26 0 -27 -4 -14 -35
7 -16 9 -17 9 -3 1 17 37 35 49 24 3 -4 -8 -36 -26 -71 -25 -52 -29 -65 -16
-65 8 0 12 4 9 10 -7 11 59 134 87 162 34 34 60 23 112 -49 65 -89 66 -93 23
-93 -20 0 -39 5 -42 10 -10 16 -91 12 -105 -5 -7 -8 -23 -15 -36 -15 -13 0
-24 -4 -24 -8 0 -5 12 -7 28 -5 26 4 28 1 54 -74 15 -43 29 -85 33 -94 5 -13
-3 -20 -32 -29 -21 -7 -64 -24 -96 -38 -31 -14 -59 -23 -61 -21 -12 13 -106
211 -106 224 0 10 10 15 28 15 15 0 48 10 72 23 34 16 45 28 47 47 1 22 1 23
-4 2 -6 -30 -54 -53 -122 -59 l-57 -5 63 -124 c35 -68 63 -127 63 -132 0 -4
-15 -16 -32 -25 -18 -9 -50 -28 -70 -42 l-37 -25 -65 98 c-62 94 -106 173
-106 189 0 10 105 83 119 83 6 0 11 -9 11 -20 0 -14 6 -20 23 -19 16 1 17 3 5
6 -16 4 -22 25 -31 123 -3 25 -4 20 -5 -17 -2 -43 -6 -54 -24 -64 -13 -7 -43
-27 -68 -46 l-44 -34 -28 58 c-15 32 -27 65 -27 73 -1 8 26 32 59 54 81 51
162 70 127 28 -10 -12 -9 -14 8 -9 48 13 174 18 197 7 22 -11 22 -11 3 5 -16
13 -34 15 -102 9 -53 -4 -83 -3 -83 4 0 5 23 12 52 14 33 4 56 11 63 22 12 19
17 20 32 5z m713 1 c0 -5 -11 -10 -25 -10 -20 0 -34 -14 -71 -71 -25 -39 -44
-73 -42 -74 2 -2 23 28 48 66 40 62 48 69 77 69 29 0 37 -7 80 -72 56 -88 55
-86 60 -70 6 17 33 15 33 -3 0 -11 -13 -15 -54 -15 -30 0 -58 5 -61 10 -8 13
-115 13 -115 1 0 -6 -15 -12 -32 -14 -32 -4 -32 -4 5 -5 20 -1 37 3 37 8 0 6
16 10 35 10 l35 0 0 -80 c0 -56 -4 -82 -12 -84 -7 -3 -63 -7 -124 -10 l-112
-5 -6 52 c-4 29 -10 62 -12 74 -5 18 -1 22 23 25 23 2 28 8 30 33 2 28 1 28
-5 5 -7 -20 -14 -25 -42 -25 l-35 0 -3 58 c-3 56 -4 57 -32 57 -23 0 -30 5
-30 19 0 15 10 19 53 25 90 10 129 7 143 -11 12 -17 13 -16 11 5 -1 18 -8 22
-37 24 -19 1 12 5 70 8 58 3 106 7 108 8 1 1 2 -2 2 -8z m235 -11 c-4 -5 -12
-9 -19 -9 -23 0 -48 -37 -53 -79 -5 -34 -9 -41 -28 -41 -17 0 -35 18 -69 68
-25 38 -46 70 -46 73 0 2 50 3 110 1 65 -2 108 -7 105 -13z m-1348 -53 l21
-64 -35 -34 c-19 -18 -44 -49 -56 -67 l-21 -35 -23 51 c-12 29 -23 64 -25 80
-3 24 2 29 31 39 34 11 53 33 50 56 -3 28 2 38 19 38 13 0 23 -17 39 -64z
m1466 32 c12 -6 27 -18 34 -27 7 -9 13 -12 13 -7 0 5 -8 18 -17 29 -17 20 -17
20 7 14 14 -4 32 -7 39 -7 10 0 3 -13 -18 -34 -19 -19 -28 -35 -21 -38 20 -6
-7 -56 -40 -73 -16 -8 -30 -18 -30 -20 0 -10 56 17 67 32 12 15 15 15 35 -5
27 -24 70 -42 104 -42 13 0 24 -3 24 -7 0 -23 -57 -173 -65 -173 -15 0 -206
38 -210 42 -2 1 1 23 5 48 5 25 9 56 8 70 0 14 -6 -5 -12 -43 -7 -37 -15 -69
-17 -71 -2 -3 -57 -2 -122 1 l-117 6 0 83 c0 49 4 84 10 84 6 0 10 -4 10 -10
0 -6 28 -10 65 -10 51 0 65 3 65 15 0 8 5 15 10 15 6 0 10 -4 10 -10 0 -11 65
-30 100 -29 15 1 8 5 -19 10 -56 11 -89 33 -97 64 -8 33 12 80 39 93 30 14
113 15 140 0z m-713 -23 c0 -21 5 -25 30 -25 29 0 30 -1 30 -49 0 -30 6 -55
15 -64 14 -14 41 -145 31 -154 -5 -5 -183 -43 -201 -43 -11 0 -75 169 -75 198
0 15 86 17 95 2 3 -5 29 -10 56 -10 l50 0 -56 75 c-31 42 -53 80 -50 85 4 6
22 10 41 10 30 0 34 -3 34 -25z m955 9 c56 -26 57 -116 2 -142 -12 -6 -16 -11
-9 -11 7 -1 24 10 37 24 l25 24 0 -29 c0 -19 5 -30 14 -30 7 0 27 -3 44 -6 29
-6 31 -4 35 27 l4 34 13 -32 c8 -19 22 -33 32 -33 9 0 19 -3 21 -7 5 -8 -94
-218 -110 -234 -6 -6 -38 2 -86 21 -41 16 -80 30 -86 30 -22 0 -20 23 9 105
16 48 30 91 30 96 0 5 -16 9 -35 9 -48 0 -101 23 -115 50 -18 34 -4 87 28 105
36 19 106 19 147 -1z m362 9 c14 -6 11 -9 -17 -14 l-35 -6 39 -2 38 -1 -7 -77
c-4 -43 -12 -84 -17 -90 -8 -10 -5 -13 8 -13 11 -1 30 -10 44 -21 l24 -21 -28
-51 c-23 -41 -99 -160 -147 -229 -3 -5 -37 12 -76 37 l-70 46 62 125 c60 119
89 164 107 164 4 0 8 -9 8 -20 0 -15 6 -20 28 -19 22 1 23 2 5 6 -18 3 -22 12
-25 46 l-3 42 -25 -37 c-20 -30 -31 -38 -57 -38 -27 0 -33 5 -49 44 -21 50
-34 51 -34 2 0 -41 -3 -44 -39 -38 -26 4 -29 8 -33 47 -1 24 0 41 5 38 4 -2 7
14 7 36 l0 41 43 0 c42 0 43 0 73 -62 l31 -62 41 56 c23 30 42 60 42 66 0 14
29 16 57 5z m409 -75 c-3 -46 -10 -96 -14 -111 -7 -26 -9 -23 -31 43 -24 76
-24 77 11 94 13 6 24 22 26 34 2 12 6 22 9 22 3 0 3 -37 -1 -82z m-287 62 c29
0 44 -16 36 -39 -9 -29 -17 -27 -72 16 l-48 36 34 -6 c18 -4 41 -7 50 -7z m-3
-65 l25 -20 -30 -68 -31 -68 -30 22 c-32 24 -34 32 -24 127 l7 61 29 -17 c15
-9 40 -26 54 -37z m147 40 c57 -9 65 -36 37 -121 l-23 -68 -19 25 c-11 13 -38
43 -60 67 -38 41 -39 44 -28 77 9 28 16 34 33 31 12 -3 39 -8 60 -11z m-2505
-49 c-2 -6 -10 -32 -18 -58 -8 -27 -17 -48 -21 -48 -3 0 -9 21 -13 46 -10 79
-10 81 25 76 16 -2 28 -9 27 -16z m41 -91 c22 -62 22 -67 7 -105 -9 -22 -16
-49 -16 -60 0 -35 -16 -21 -39 34 -16 40 -21 64 -16 93 9 53 25 103 33 103 4
0 18 -29 31 -65z m185 0 l28 -64 -53 -60 c-29 -33 -56 -58 -61 -55 -5 3 -20
31 -34 61 -20 46 -22 59 -12 72 44 58 91 111 97 111 5 0 20 -29 35 -65z m2239
-4 c20 -25 37 -54 37 -64 0 -22 -47 -117 -58 -116 -4 0 -30 28 -58 62 l-50 62
29 62 c33 72 37 71 100 -6z m143 -43 c4 -42 1 -63 -17 -103 l-22 -50 -13 45
c-6 25 -16 56 -21 69 -7 18 -3 39 16 88 l26 65 12 -31 c7 -16 15 -54 19 -83z
m-2316 -80 c13 -24 52 -87 87 -141 l64 -97 -43 -50 c-24 -28 -46 -50 -50 -50
-9 0 -76 82 -142 174 l-56 79 18 29 c23 35 81 98 91 98 4 0 18 -19 31 -42z
m2084 1 c65 -71 66 -81 16 -152 -24 -34 -68 -92 -97 -129 l-55 -68 -44 55
c-24 29 -44 57 -44 60 0 4 20 34 44 68 24 34 62 95 86 135 23 39 46 72 50 72
4 0 24 -18 44 -41z m172 -62 c5 -34 0 -51 -21 -90 -26 -46 -45 -54 -45 -18 0
10 -6 34 -14 52 -13 31 -12 37 18 97 l31 63 13 -31 c7 -17 15 -50 18 -73z
m-2442 35 l28 -58 -20 -59 -19 -60 -28 45 c-16 25 -28 57 -27 75 1 37 22 115
31 115 4 0 20 -26 35 -58z m1184 -139 l-3 -158 -86 -3 -87 -3 -5 28 c-2 15
-14 69 -25 118 -11 50 -23 107 -26 127 l-7 37 53 4 c29 2 83 4 121 5 l68 2 -3
-157z m243 141 c4 -2 -3 -52 -16 -111 -39 -181 -46 -205 -62 -199 -8 3 -46 6
-84 6 l-69 0 0 161 0 162 112 -7 c62 -4 115 -9 119 -12z m-466 -136 c15 -73
30 -142 32 -154 4 -19 -2 -22 -57 -33 -34 -7 -68 -15 -75 -18 -14 -5 -31 28
-101 189 -50 116 -51 110 41 129 140 30 127 39 160 -113z m601 116 c44 -9 83
-20 87 -24 9 -8 -30 -114 -85 -233 -21 -45 -36 -66 -45 -63 -8 3 -43 11 -78
18 -52 9 -64 15 -60 27 7 23 65 274 65 284 0 11 30 9 116 -9z m272 -88 l32
-17 -36 -57 c-34 -52 -103 -151 -138 -196 -15 -18 -17 -18 -78 4 -35 13 -64
24 -66 25 -1 1 18 46 43 99 25 54 55 121 66 151 l20 53 63 -23 c34 -12 77 -30
94 -39z m-1121 37 c3 -10 30 -75 59 -146 30 -70 54 -129 54 -131 0 -2 -28 -13
-61 -26 l-62 -22 -23 28 c-32 39 -164 235 -164 244 0 9 149 69 173 70 10 0 20
-8 24 -17z m-618 -84 c28 -42 72 -100 98 -129 44 -50 46 -53 35 -84 -7 -18
-12 -40 -12 -49 0 -27 -12 -20 -71 41 -76 77 -139 160 -139 181 0 21 30 124
35 119 2 -1 26 -37 54 -79z m2259 39 c22 -73 22 -83 -6 -124 -34 -51 -149
-181 -166 -187 -8 -3 -18 13 -27 43 -15 48 -15 49 13 79 38 40 158 202 158
213 0 21 20 5 28 -24z m-2360 -131 c-3 -17 -10 -8 -31 37 -36 79 -34 104 4 38
16 -29 29 -63 27 -75z m2424 40 c-25 -51 -31 -57 -31 -35 -1 28 51 126 57 108
2 -6 -10 -38 -26 -73z m-1872 6 c24 -38 65 -97 92 -132 26 -35 48 -67 48 -70
0 -3 -21 -19 -46 -34 l-46 -28 -52 48 c-67 63 -176 185 -176 198 0 12 107 85
125 85 6 0 31 -30 55 -67z m1402 37 c77 -51 77 -48 -6 -141 -40 -46 -92 -102
-114 -123 l-41 -39 -50 33 -51 32 34 41 c18 23 61 84 95 135 34 50 68 92 75
92 7 0 33 -14 58 -30z m-1531 -142 c35 -40 85 -93 111 -117 27 -25 48 -48 48
-52 0 -11 -50 -69 -59 -69 -26 0 -251 193 -251 215 0 14 68 95 79 95 5 0 37
-33 72 -72z m1663 28 c20 -23 36 -48 36 -54 0 -19 -235 -213 -257 -211 -4 0
-20 15 -36 34 l-27 33 69 67 c38 37 89 91 113 121 23 30 48 54 55 54 6 0 28
-20 47 -44z m-1931 -123 c39 -42 67 -81 67 -93 0 -12 5 -30 10 -41 33 -61 -66
22 -136 115 -48 63 -67 99 -78 151 -6 29 -5 29 31 -15 21 -25 68 -77 106 -117z
m2207 98 c-12 -47 -93 -159 -154 -213 -49 -44 -59 -47 -46 -12 6 14 10 34 10
44 0 10 33 52 73 93 40 41 84 91 98 111 28 42 35 34 19 -23z m-1172 -143 l-3
-133 -37 -3 -36 -3 -30 78 c-17 44 -36 101 -43 127 -15 60 -8 66 84 66 l68 0
-3 -132z m169 118 c7 -7 1 -29 -50 -168 l-31 -88 -38 0 -38 0 0 136 0 136 75
-4 c41 -3 78 -8 82 -12z m-318 -98 c19 -57 40 -115 47 -130 16 -30 13 -33 -38
-42 l-36 -7 -55 83 c-30 46 -64 102 -76 125 l-21 42 58 11 c31 7 62 14 67 15
6 2 12 4 15 4 3 1 21 -45 39 -101z m434 82 l58 -12 -27 -46 c-41 -71 -112
-178 -127 -190 -8 -7 -29 -9 -51 -5 -29 4 -36 10 -32 22 3 9 24 68 46 131 22
63 40 116 40 118 0 2 8 1 18 -2 9 -2 43 -10 75 -16z m-1108 -100 c43 -36 94
-76 114 -90 36 -24 37 -26 26 -62 l-10 -38 -36 19 c-55 29 -120 72 -176 116
-55 42 -61 59 -42 114 13 37 4 41 124 -59z m1783 48 c7 -25 7 -48 2 -58 -14
-27 -103 -92 -202 -148 l-57 -33 -10 38 c-10 34 -9 39 11 53 54 37 142 105
185 145 27 24 51 44 54 45 4 0 11 -19 17 -42z m-1275 -18 c16 -28 49 -81 73
-117 24 -36 44 -69 44 -73 0 -4 -12 -12 -26 -18 -24 -12 -30 -7 -129 90 -58
57 -106 109 -107 117 -3 12 74 48 107 50 6 1 23 -22 38 -49z m755 23 c17 -7
32 -18 32 -23 0 -6 -47 -57 -105 -115 -102 -101 -106 -104 -135 -94 -16 6 -30
13 -30 14 0 2 20 32 44 67 24 35 58 89 76 120 35 63 38 64 118 31z m142 -84
c0 -16 -241 -189 -262 -189 -5 0 -18 5 -28 10 -17 9 -7 23 93 123 l112 113 43
-25 c23 -14 42 -29 42 -32z m-957 -56 c82 -82 104 -110 95 -120 -15 -19 -33
-16 -81 13 -75 47 -207 149 -207 161 0 12 58 53 76 53 7 0 59 -48 117 -107z
m-475 -22 c53 -38 111 -76 128 -84 23 -12 33 -25 36 -47 3 -16 0 -30 -5 -30
-14 0 -145 68 -192 99 -22 15 -45 38 -51 51 -15 33 -26 80 -19 80 4 0 50 -31
103 -69z m1807 22 c-9 -28 -26 -57 -42 -71 -38 -30 -211 -117 -219 -109 -3 3
-2 18 3 33 6 18 31 41 76 68 37 23 93 60 124 83 32 24 61 43 65 43 4 0 1 -21
-7 -47z m-1479 -10 c32 -25 92 -67 131 -93 56 -36 70 -49 61 -58 -19 -19 -33
-15 -161 49 -168 83 -164 80 -136 118 13 17 28 31 34 31 6 0 38 -21 71 -47z
m1154 23 c12 -14 20 -31 17 -39 -8 -20 -265 -149 -291 -145 -38 5 -22 25 69
86 50 33 106 74 125 90 43 38 50 39 80 8z m-1248 -76 c31 -24 226 -120 245
-120 7 0 13 -9 13 -20 0 -13 -7 -20 -19 -20 -25 0 -159 45 -238 81 -60 27 -63
30 -57 56 12 51 18 53 56 23z m1292 4 c3 -9 6 -26 6 -38 0 -20 -15 -29 -119
-69 -141 -55 -201 -68 -201 -43 0 10 15 23 38 32 51 21 235 114 247 125 13 13
22 11 29 -7z m-539 -25 c139 -26 234 -109 206 -180 -63 -162 -512 -188 -652
-38 -73 78 -1 175 159 213 70 17 208 20 287 5z m749 -40 c-36 -41 -205 -139
-239 -139 -3 0 2 11 10 24 9 13 49 40 90 59 41 20 91 47 111 61 43 29 56 26
28 -5z m-1742 12 c2 -5 45 -30 96 -55 51 -24 102 -55 113 -68 17 -19 20 -19
15 -4 -5 14 -3 17 11 11 27 -10 214 -53 248 -57 18 -2 31 -10 33 -19 3 -14 -5
-16 -45 -12 -88 8 -178 26 -208 41 -16 9 -35 15 -42 14 -7 -2 -45 13 -85 32
-77 37 -186 125 -156 126 9 0 18 -4 20 -9z m338 -84 c41 -15 94 -31 118 -37
25 -7 42 -17 42 -25 0 -8 -6 -15 -12 -15 -24 1 -143 28 -213 49 -60 18 -73 26
-82 51 -14 38 -8 43 36 21 20 -10 70 -30 111 -44z m1196 28 c-8 -31 -37 -45
-151 -73 -130 -32 -155 -35 -155 -18 0 9 16 20 43 27 54 14 227 77 247 89 20
12 24 6 16 -25z m-51 -85 c-21 -22 -45 -33 -99 -44 -136 -28 -212 -28 -173 1
6 5 53 17 103 26 50 10 109 24 130 31 61 23 71 19 39 -14z m-1225 -45 c14 -8
21 -14 15 -14 -5 0 -21 6 -35 14 -14 8 -20 14 -15 14 6 0 21 -6 35 -14z m1226
0 c-11 -8 -27 -15 -35 -15 -10 1 -7 6 9 15 33 19 50 18 26 0z m-1024 -31 c67
-5 99 -11 102 -20 4 -11 -10 -14 -67 -14 -80 0 -133 12 -162 36 -19 15 -19 15
5 10 14 -3 69 -9 122 -12z m948 11 c0 -2 -15 -12 -32 -21 -44 -23 -223 -34
-216 -13 3 10 32 16 98 21 51 4 98 9 104 12 15 6 46 7 46 1z m-780 -65 c8 -5
11 -12 7 -16 -10 -10 -127 -9 -158 1 l-24 9 25 7 c37 10 133 10 150 -1z m630
1 c0 -15 -41 -24 -108 -24 -66 1 -86 8 -62 23 20 13 170 13 170 1z m-410 -37
c63 -3 119 -10 124 -14 16 -16 -125 -22 -232 -11 -59 6 -113 11 -119 11 -7 0
-13 4 -13 9 0 13 46 20 90 15 19 -2 87 -7 150 -10z m229 10 c17 -4 31 -10 31
-14 0 -13 -94 -19 -115 -7 -19 10 -19 10 0 18 26 10 45 10 84 3z"/>
<path d="M2673 2733 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M2655 2630 c-4 -6 8 -10 29 -10 20 0 36 5 36 10 0 6 -13 10 -29 10
-17 0 -33 -4 -36 -10z"/>
<path d="M2977 2676 c-7 -55 -4 -77 9 -64 3 3 4 32 2 64 l-3 59 -8 -59z"/>
<path d="M3250 2702 c0 -5 14 -12 30 -14 17 -3 30 -1 30 4 0 4 -6 8 -14 8 -8
0 -21 3 -30 6 -9 3 -16 2 -16 -4z"/>
<path d="M1670 2690 c0 -13 30 -13 50 0 11 7 7 10 -17 10 -18 0 -33 -4 -33
-10z"/>
<path d="M1946 2364 c-9 -23 -8 -24 14 -24 23 0 26 13 6 29 -11 9 -15 8 -20
-5z"/>
<path d="M1650 2331 c0 -34 3 -41 20 -41 11 0 20 5 20 11 0 5 -4 8 -9 4 -5 -3
-12 -1 -15 3 -3 5 10 16 29 25 29 14 35 14 35 2 0 -8 -8 -19 -17 -25 -16 -10
-16 -10 2 -8 15 3 20 11 20 33 0 28 -3 30 -42 33 l-43 3 0 -40z m40 19 c-19
-13 -30 -13 -30 0 0 6 10 10 23 10 18 0 19 -2 7 -10z"/>
<path d="M2270 2401 c0 -5 9 -7 20 -4 11 3 20 7 20 9 0 2 -9 4 -20 4 -11 0
-20 -4 -20 -9z"/>
<path d="M2230 2370 c0 -16 2 -30 4 -30 2 0 6 14 8 30 3 17 1 30 -4 30 -4 0
-8 -13 -8 -30z"/>
<path d="M2427 2383 c-13 -12 -7 -23 13 -23 13 0 18 5 14 15 -7 16 -17 19 -27
8z"/>
<path d="M2290 2285 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M2700 2389 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2750 2369 c0 -28 -2 -31 -26 -25 -17 5 -23 3 -19 -4 8 -12 45 -13
64 -1 9 6 10 11 2 15 -18 11 -3 26 26 26 33 0 27 5 -14 13 -31 6 -33 5 -33
-24z"/>
<path d="M2944 2383 c-28 -29 -9 -63 37 -63 29 0 43 21 34 50 -7 23 -52 31
-71 13z m61 -28 c0 -26 -49 -34 -59 -10 -9 25 3 37 32 33 19 -2 27 -9 27 -23z"/>
<path d="M3887 3854 c-3 -5 -10 -27 -16 -49 -24 -88 -130 -189 -230 -219 l-46
-14 30 -7 c54 -14 119 -52 164 -96 42 -43 91 -128 91 -160 0 -39 15 -24 33 33
26 86 114 175 206 208 l63 24 -38 11 c-117 35 -192 107 -233 226 -10 28 -21
47 -24 43z"/>
<path d="M4212 3260 c-13 -34 -60 -79 -89 -87 -24 -5 -23 -6 14 -27 21 -12 49
-38 62 -59 l25 -37 12 27 c15 35 50 67 89 81 24 8 26 11 8 11 -29 1 -83 48
-99 86 l-12 30 -10 -25z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
